<template>
  <FocusTrap>
    <div class="card" id="vaultreceipt_list_card" >

      <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Vault Receipt List</h5>

        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>

        </div>
      </div>


      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="voucher_table"
                 class="table  table-hover  table-bordered table-condensed table-columned"
                 data-search="false"
                 data-pagination="true"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-detail-view="false"
                 data-detail-formatter="extrusionDeliveryDetailFormatter"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-sortable="true" data-width="75" >S.No</th>
              <th data-width="100" data-field="vch_no" data-sortable="true" data-align="center">Doc No</th>
              <th data-width="107" data-field="doc_date" data-sortable="true" data-formatter="dateFormatter" >Date</th>
              <th data-field="ledger" data-formatter="ledgerName_from_ledger" data-sortable="true">Ledger</th>
              <th data-field="remarks" data-sortable="true">Narration</th>
              <th data-field="amount" data-width="200" data-align="right" data-sortable="true" data-formatter="indianFormat">Amount</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <!--        <a href="#" class="dropdown-item" ><i class="icon-puzzle"></i>Breakup</a>-->
          <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
        </div>
      </div>
      <!-- End of Context Menu -->

      <vmodal name="voucher_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="75%" :height="475" @before-open="beforeOpen" @before-close="beforeClose" >
        <VoucherForm v-if="voucher.id > 1" v-bind:myvoucher="voucher" v-on:vaultreceipt_window_closed="closeModal" v-on:vaultreceipt_saved="loadData" ></VoucherForm>
      </vmodal>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->


      <FocusTrap>
        <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Cashier:</label>
                  <div class="col-md-9">
                    <select id="cmbCashier" class="form-control" v-model="cashier_id" >
                      <option value="0" selected>All</option>
                      <option v-for="camp in ledgers" v-bind:value="camp.id">
                        {{ camp.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>


    </div>
  </FocusTrap>
</template>

<script>
import moment from 'moment-timezone'
import VoucherForm from '@/views/xpos/vaultreceipt/VaultReceiptForm.vue'
import {userService} from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'VaultPaymentView',
  components: {
    VoucherForm
  },
  store,
  data () {
    return {
      mytable: {},
      voucher: JSON.parse('{"id":0,"type":0,"status":0,"finyear":0,"iscredit":false,"vchtype":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"status":0,"type":0,"name":"","alias":"","group":{"id":0,"status":0,"type":0,"name":"","alias":"","parent":0,"nature":0,"level":0,"is_profit":false,"is_subledger":false,"is_net_bal":false},"address":{"name":"","street":"","city":"","pin":"","state_cd":0,"email":"","tel":"","mobile":""},"open_bal":0,"gstin":""},"amount":0,"remarks":"","ibr":0,"locked":false,"list":[]}'),
      branches: [],
      ledgers:[],
      from_date: moment().add(-1, 'days').format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      selected_ibr: 1,
      voucher_id : 0,
      cashier_id : 0,
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    self.$data.mytable = $('#voucher_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#voucher_table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyVoucher(id);
        } else if ($(e.target).text() === 'Cancel') {
          self.cancelVoucher(id);
        }
      }
    })

    $('#filter_window').on('shown.bs.modal', function () {
      $('#txtfrom').trigger('focus');
    });

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

      $('#voucher_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();

    });

    self.$modal.show('filter_window');

    self.loadCashierLedgers();
  },
  methods: {
    showModal () {
      this.$modal.show('voucher_window');
    },
    beforeOpen () {
    },
    beforeClose () {
    },
    closeModal () {
      this.$modal.hide('voucher_window');
    },
    showFilterWindow(){
      this.$modal.show('filter_window');
    },
    loadCashierLedgers(){
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#vaultreceipt_list_card').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/cashier_ledgers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            resp.data.forEach(function (ldgr) {
              self.$data.ledgers.push(ldgr);
            });
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes', text: err.toString(), type: 'error' })
      }).finally(function () {
        $('#vaultreceipt_list_card').unblock();
      });
    },
    loadData () {
      const self = this;

      self.$modal.hide('voucher_window');
      self.$modal.hide('filter_window');
      let ibr = self.$store.state.user.branch.id;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#txtsearch').val('');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevouchers/vchtype/1006?finyear=${self.$store.state.user.finyear}&ibr=${ibr}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&ledger_id=${self.$data.cashier_id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            console.log(JSON.stringify(resp.data));
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock();
      });

    },
    modifyVoucher (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.voucher = {}
      fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/simplevoucher/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.voucher = resp.data;

          self.$modal.show('voucher_window');
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      })
    },
    cancelVoucher (id) {
      const self = this;

      self.$data.voucher.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.voucher)
      }

      swal({
        title: 'What is the reason for cancel this voucher?',
        input: 'text',
        inputPlaceholder: 'Enter Remarks here',
        showCancelButton: true,
        inputClass: 'form-control',
        inputValidator: function (value) {
          return !value && 'You need to write something!'
        }
      }).then(function (result) {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/simplevoucher/${id}?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('voucher_window');
            if (resp.ok) {
              self.loadData();
              swal({
                title: 'Canceled!',
                text: 'Your request has been processed',
                type: 'success'
              })
            } else {
              swal({ title: 'Oops', text: resp.msg, type: 'info' })
            }
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })
    },
  }
}

</script>

<style scoped>

</style>
